//import styles from './App.css';
import * as desktop from './components/styles/desktop';
import * as phone from './components/styles/phone';
import * as tablet from './components/styles/tablet';

// Import Various Screen Sizes
import DesktopBreakpoint from './components/responsive_utilities/desktop_breakpoint';
import TabletBreakpoint from './components/responsive_utilities/tablet_breakpoint';
import PhoneBreakpoint from './components/responsive_utilities/phone_breakpoint';

function App() {
  return (
    <div>
      {/* Settings for Desktop */}

      <DesktopBreakpoint>
        <desktop.Container>
          <desktop.InnerContainer>
            <desktop.MarkBixler>{'{ mark.bixler }'}</desktop.MarkBixler>
            <desktop.AvatarStack>
              <desktop.Avatar src="../images/2020-avatar.png"></desktop.Avatar>
              <desktop.Hr1></desktop.Hr1>
              <desktop.Hr2></desktop.Hr2>
            </desktop.AvatarStack>
            <desktop.Links>
              <a
                href="https://medium.com/@bixlerm"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.Medium src="../images/medium_512.png"></desktop.Medium>
              </a>
              <a
                href="https://www.linkedin.com/in/mark-bixler-5835707a/"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.LinkedIn src="../images/linkedin_512.png"></desktop.LinkedIn>
              </a>
              <a
                href="https://github.com/mark-bixler"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.Github src="../images/github_512.png"></desktop.Github>
              </a>
            </desktop.Links>
            <desktop.Hr3></desktop.Hr3>
            <desktop.AwsDevProStack>
              <a
                href="https://www.youracclaim.com/badges/19bf8eb3-8d49-4eea-81f9-5e5fc95dc372/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.AwsDevPro src="../images/AWS-DevOpsEngineer-Professional-2020.png"></desktop.AwsDevPro>
              </a>
              <a
                href="https://www.youracclaim.com/badges/6a69b8d8-c6ee-4b16-a445-c4346a44ddf0/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.AwsSolPro src="../images/AWS-SolArchitect-Professional-2020.png"></desktop.AwsSolPro>
              </a>
              <a
                href="https://www.youracclaim.com/badges/a7803026-9616-4116-b7ea-134160c0f801/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.AwsDevAssc src="../images/AWS-Developer-Associate-2020.png"></desktop.AwsDevAssc>
              </a>
              <a
                href="https://www.youracclaim.com/badges/83cb374d-a974-41d4-867a-7d6bcc04a5b8/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.AwsSysOps src="../images/AWS-SysOpAdmin-Associate-2020.png"></desktop.AwsSysOps>
              </a>
              <a
                href="https://www.youracclaim.com/badges/6ea0f78b-1b79-4b3d-8459-33281622ea0a/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <desktop.AwsSolAssc src="../images/AWS-SolArchitect-Associate-2020.png"></desktop.AwsSolAssc>
              </a>
            </desktop.AwsDevProStack>
          </desktop.InnerContainer>
        </desktop.Container>
      </DesktopBreakpoint>
      <PhoneBreakpoint>
        <phone.InnerContainer>
          <phone.MarkBixler>{'{ mark.bixler }'}</phone.MarkBixler>
          <phone.Hr1Stack>
            <phone.Hr1></phone.Hr1>
            <phone.Avatar src="../images/2020-avatar.png"></phone.Avatar>
            <phone.Hr2></phone.Hr2>
          </phone.Hr1Stack>
          <phone.Links>
            <a
              href="https://medium.com/@bixlerm"
              target="_blank"
              rel="noreferrer"
            >
              <phone.Medium src="../images/medium_512.png"></phone.Medium>
            </a>
            <a
              href="https://www.linkedin.com/in/mark-bixler-5835707a/"
              target="_blank"
              rel="noreferrer"
            >
              <phone.LinkedIn src="../images/linkedin_512.png"></phone.LinkedIn>
            </a>
            <a
              href="https://github.com/mark-bixler"
              target="_blank"
              rel="noreferrer"
            >
              <phone.Github src="../images/github_512.png"></phone.Github>
            </a>
          </phone.Links>
          <phone.Hr3></phone.Hr3>
          <phone.Aws>
            <phone.AwsDevProStackStack>
              <phone.AwsDevProStack>
                <a
                  href="https://www.youracclaim.com/badges/19bf8eb3-8d49-4eea-81f9-5e5fc95dc372/public_url"
                  target="_blank"
                  rel="noreferrer"
                >
                  <phone.AwsDevPro src="../images/AWS-DevOpsEngineer-Professional-2020.png"></phone.AwsDevPro>
                </a>
                <a
                  href="https://www.youracclaim.com/badges/6a69b8d8-c6ee-4b16-a445-c4346a44ddf0/public_url"
                  target="_blank"
                  rel="noreferrer"
                >
                  <phone.AwsSolPro src="../images/AWS-SolArchitect-Professional-2020.png"></phone.AwsSolPro>
                </a>
                <a
                  href="https://www.youracclaim.com/badges/83cb374d-a974-41d4-867a-7d6bcc04a5b8/public_url"
                  target="_blank"
                  rel="noreferrer"
                >
                  <phone.AwsSysOps src="../images/AWS-SysOpAdmin-Associate-2020.png"></phone.AwsSysOps>
                </a>
                <a
                  href="https://www.youracclaim.com/badges/a7803026-9616-4116-b7ea-134160c0f801/public_url"
                  target="_blank"
                  rel="noreferrer"
                >
                  <phone.AwsDevAssc src="../images/AWS-Developer-Associate-2020.png"></phone.AwsDevAssc>
                </a>
              </phone.AwsDevProStack>
              <a
                href="https://www.youracclaim.com/badges/6ea0f78b-1b79-4b3d-8459-33281622ea0a/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <phone.AwsSolAssc src="../images/AWS-SolArchitect-Associate-2020.png"></phone.AwsSolAssc>
              </a>
            </phone.AwsDevProStackStack>
          </phone.Aws>
        </phone.InnerContainer>
      </PhoneBreakpoint>
      <TabletBreakpoint>
        <tablet.InnerContainer>
          <tablet.MarkBixler>{'{ mark.bixler }'}</tablet.MarkBixler>
          <tablet.Hr1Stack>
            <tablet.Hr1></tablet.Hr1>
            <tablet.Avatar src="../images/2020-avatar.png"></tablet.Avatar>
            <tablet.Hr3></tablet.Hr3>
          </tablet.Hr1Stack>
          <tablet.Links>
            <a
              href="https://medium.com/@bixlerm"
              target="_blank"
              rel="noreferrer"
            >
              <tablet.Medium src="../images/medium_512.png"></tablet.Medium>
            </a>
            <a
              href="https://www.linkedin.com/in/mark-bixler-5835707a/"
              target="_blank"
              rel="noreferrer"
            >
              <tablet.LinkedIn src="../images/linkedin_512.png"></tablet.LinkedIn>
            </a>
            <a
              href="https://github.com/mark-bixler"
              target="_blank"
              rel="noreferrer"
            >
              <tablet.Github src="../images/github_512.png"></tablet.Github>
            </a>
          </tablet.Links>
          <tablet.Hr2></tablet.Hr2>
          <tablet.Aws>
            <tablet.AwsDevProStack>
              <a
                href="https://www.youracclaim.com/badges/19bf8eb3-8d49-4eea-81f9-5e5fc95dc372/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <tablet.AwsDevPro src="../images/AWS-DevOpsEngineer-Professional-2020.png"></tablet.AwsDevPro>
              </a>
              <a
                href="https://www.youracclaim.com/badges/6a69b8d8-c6ee-4b16-a445-c4346a44ddf0/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <tablet.AwsSolPro src="../images/AWS-SolArchitect-Professional-2020.png"></tablet.AwsSolPro>
              </a>
              <a
                href="https://www.youracclaim.com/badges/a7803026-9616-4116-b7ea-134160c0f801/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <tablet.AwsDevAssc src="../images/AWS-Developer-Associate-2020.png"></tablet.AwsDevAssc>
              </a>
              <a
                href="https://www.youracclaim.com/badges/6ea0f78b-1b79-4b3d-8459-33281622ea0a/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <tablet.AwsSolAssc src="../images/AWS-SolArchitect-Associate-2020.png"></tablet.AwsSolAssc>
              </a>
              <a
                href="https://www.youracclaim.com/badges/83cb374d-a974-41d4-867a-7d6bcc04a5b8/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <tablet.AwsSysOps src="../images/AWS-SysOpAdmin-Associate-2020.png"></tablet.AwsSysOps>
              </a>
            </tablet.AwsDevProStack>
          </tablet.Aws>
        </tablet.InnerContainer>
      </TabletBreakpoint>
    </div>
  );
}

export default App;
