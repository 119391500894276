import styled from "styled-components";


export const  Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const  InnerContainer = styled.div`
  width: 971px;
  height: 1366px;
  background-color: white;
  flex-direction: column;
  display: flex;
  margin-left: 200px;

`;

export const  MarkBixler = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: rgba(117,110,110,1);
  font-size: 36px;
  width: 229px;
  height: 29px;
  margin-top: 70px;
  margin-left: 366px;
`;

export const  Avatar = styled.img`
  top: 0px;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 350px;
  object-fit: contain;
`;

export const  Hr1 = styled.div`
  top: 21px;
  left: 0px;
  width: 900px;
  height: 3px;
  position: absolute;
  background-color: #E6E6E6;
`;

export const  Hr2 = styled.div`
  top: 183px;
  left: 0px;
  width: 900px;
  height: 3px;
  position: absolute;
  background-color: #E6E6E6;
`;

export const  AvatarStack = styled.div`
  width: 900px;
  height: 200px;
  margin-top: 22px;
  margin-left: 35px;
  position: relative;
`;

export const  Links = styled.div`
  width: 526px;
  height: 150px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 36px;
  margin-left: 222px;
  display: flex;
`;

export const  Medium = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

export const  LinkedIn = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

export const  Github = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

export const  Hr3 = styled.div`
  width: 900px;
  height: 3px;
  background-color: #E6E6E6;
  margin-top: 51px;
  margin-left: 35px;
`;

export const  AwsDevPro = styled.img`
  top: 0px;
  left: 70px;
  width: 150px;
  height: 150px;
  position: absolute;
  object-fit: contain;
`;

export const  AwsSolPro = styled.img`
  top: 0px;
  left: 207px;
  width: 150px;
  height: 150px;
  position: absolute;
  object-fit: contain;
`;

export const  AwsDevAssc = styled.img`
  top: 118px;
  left: 0px;
  width: 150px;
  height: 150px;
  position: absolute;
  object-fit: contain;
`;

export const  AwsSysOps = styled.img`
  top: 118px;
  left: 139px;
  width: 150px;
  height: 150px;
  position: absolute;
  object-fit: contain;
`;

export const  AwsSolAssc = styled.img`
  top: 118px;
  left: 278px;
  width: 150px;
  height: 150px;
  position: absolute;
  object-fit: contain;
`;

export const  AwsDevProStack = styled.div`
  width: 428px;
  height: 268px;
  margin-top: 43px;
  margin-left: 271px;
  position: relative;
`;
