import styled from 'styled-components';

export const InnerContainer = styled.div`
  display: flex;
  width: 768px;
  height: 1000px;
  background-color: rgba(255,255,255,1);
  flex-direction: column;
`;

export const MarkBixler = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: rgba(117,110,110,1);
  font-size: 33px;
  width: 226px;
  height: 29px;
  margin-top: 85px;
  margin-left: 271px;
`;

export const Hr1 = styled.div`
  top: 24px;
  left: 0px;
  width: 517px;
  height: 3px;
  position: absolute;
  background-color: #E6E6E6;
`;

export const Avatar = styled.img`
  top: 0px;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 158px;
  object-fit: contain;
`;

export const Hr3 = styled.div`
  top: 181px;
  left: 0px;
  width: 517px;
  height: 3px;
  position: absolute;
  background-color: #E6E6E6;
`;

export const Hr1Stack = styled.div`
  width: 517px;
  height: 200px;
  margin-top: 13px;
  margin-left: 126px;
  position: relative;
`;

export const Links = styled.div`
  width: 391px;
  height: 109px;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 188px;
  display: flex;
`;

export const Medium = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

export const LinkedIn = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

export const Github = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

export const Hr2 = styled.div`
  width: 517px;
  height: 3px;
  background-color: #E6E6E6;
  margin-top: 10px;
  margin-left: 126px;
`;

export const Aws = styled.div`
  width: 428px;
  height: 268px;
  flex-direction: column;
  display: flex;
  margin-top: 36px;
  margin-left: 188px;
`;

export const AwsDevPro = styled.img`
  top: 0px;
  left: 55px;
  width: 125px;
  height: 125px;
  position: absolute;
  object-fit: contain;
`;

export const AwsSolPro = styled.img`
  top: 0px;
  left: 167px;
  width: 125px;
  height: 125px;
  position: absolute;
  object-fit: contain;
`;

export const AwsDevAssc = styled.img`
  top: 97px;
  left: 0px;
  width: 125px;
  height: 125px;
  position: absolute;
  object-fit: contain;
`;

export const AwsSolAssc = styled.img`
  top: 98px;
  left: 223px;
  width: 125px;
  height: 125px;
  position: absolute;
  object-fit: contain;
`;

export const AwsSysOps = styled.img`
  top: 98px;
  left: 112px;
  width: 125px;
  height: 125px;
  position: absolute;
  object-fit: contain;
`;

export const AwsDevProStack = styled.div`
  width: 348px;
  height: 223px;
  margin-top: -7px;
  margin-left: 27px;
  position: relative;
`;