import styled from 'styled-components';

export const InnerContainer = styled.div`
  display: flex;
  width: 375px;
  height: 812px;
  background-color: rgba(255,255,255,1);
  flex-direction: column;
`;

export const MarkBixler = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: rgba(117,110,110,1);
  font-size: 26px;
  width: 175px;
  height: 31px;
  margin-top: 78px;
  margin-left: 100px;
`;

export const Hr1 = styled.div`
  top: 26px;
  left: 0px;
  width: 272px;
  height: 3px;
  position: absolute;
  background-color: #E6E6E6;
`;

export const Avatar = styled.img`
  top: 0px;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 37px;
  object-fit: contain;
`;

export const Hr2 = styled.div`
  top: 187px;
  left: 5px;
  width: 272px;
  height: 3px;
  position: absolute;
  background-color: #E6E6E6;
`;

export const Hr1Stack = styled.div`
  width: 277px;
  height: 200px;
  margin-top: 24px;
  margin-left: 51px;
  position: relative;
`;

export const Links = styled.div`
  width: 293px;
  height: 85px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  margin-left: 41px;
  display: flex;
`;

export const Medium = styled.img`
  width: 100%;
  height: 75px;
  left: -2px;
  top: -6px;
  object-fit: contain;
`;

export const LinkedIn = styled.img`
  width: 100%;
  height: 75px;
  object-fit: contain;
`;

export const Github = styled.img`
  width: 100%;
  height: 75px;
  object-fit: contain;
`;

export const Hr3 = styled.div`
  width: 272px;
  height: 3px;
  background-color: #E6E6E6;
  margin-top: 24px;
  margin-left: 57px;
`;

export const Aws = styled.div`
  width: 101px;
  height: 101px;
  flex-direction: column;
  display: flex;
  margin-top: 128px;
  margin-left: 228px;
`;

export const AwsDevPro = styled.img`
  top: 0px;
  left: 45px;
  width: 101px;
  height: 101px;
  position: absolute;
  object-fit: contain;
`;

export const AwsSolPro = styled.img`
  top: 0px;
  left: 136px;
  width: 101px;
  height: 101px;
  position: absolute;
  object-fit: contain;
`;

export const AwsSysOps = styled.img`
  top: 79px;
  left: 91px;
  width: 101px;
  height: 101px;
  position: absolute;
  object-fit: contain;
`;

export const AwsDevAssc = styled.img`
  top: 79px;
  left: 0px;
  width: 101px;
  height: 101px;
  position: absolute;
  object-fit: contain;
`;

export const AwsDevProStack = styled.div`
  top: 0px;
  left: 0px;
  width: 237px;
  height: 180px;
  position: absolute;
`;

export const AwsSolAssc = styled.img`
  top: 79px;
  left: 182px;
  width: 101px;
  height: 101px;
  position: absolute;
  object-fit: contain;
`;

export const AwsDevProStackStack = styled.div`
  width: 283px;
  height: 180px;
  margin-top: -79px;
  margin-left: -182px;
  position: relative;
`;